exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-article-index-tsx": () => import("./../../../src/pages/article/index.tsx" /* webpackChunkName: "component---src-pages-article-index-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-2-tsx": () => import("./../../../src/pages/contact2.tsx" /* webpackChunkName: "component---src-pages-contact-2-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kw-article-tsx": () => import("./../../../src/pages/kw_article.tsx" /* webpackChunkName: "component---src-pages-kw-article-tsx" */),
  "component---src-pages-markdown-remark-parent-file-relative-directory-markdown-remark-parent-file-name-tsx": () => import("./../../../src/pages/{MarkdownRemark.parent__(File)__relativeDirectory}/{MarkdownRemark.parent__(File)__name}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-parent-file-relative-directory-markdown-remark-parent-file-name-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-npuser-index-tsx": () => import("./../../../src/pages/npuser/index.tsx" /* webpackChunkName: "component---src-pages-npuser-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-thangiswho-index-tsx": () => import("./../../../src/pages/thangiswho/index.tsx" /* webpackChunkName: "component---src-pages-thangiswho-index-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-uipath-tsx": () => import("./../../../src/pages/uipath.tsx" /* webpackChunkName: "component---src-pages-uipath-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user/[...].tsx" /* webpackChunkName: "component---src-pages-user-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-newspicks-tsx": () => import("./../../../src/templates/newspicks.tsx" /* webpackChunkName: "component---src-templates-newspicks-tsx" */),
  "component---src-templates-yahoo-tsx": () => import("./../../../src/templates/yahoo.tsx" /* webpackChunkName: "component---src-templates-yahoo-tsx" */)
}

